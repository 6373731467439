import React from 'react';
import './ourOffices.css'
import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';
import {  Navigation } from "swiper";
import { BottonsNavSwiper } from 'components/Bottons/Bottons';
import OfficeItem from './OfficeItem';

export default function OurOffice() {

  const { t } = useTranslation('translation', { keyPrefix: 'contactsPage' });

  return (
    <section className=' office-about sec_pad -bg-section overflow-hidden'>
        <div className="container ">
            <div className='row justify-content-center'>
                <div className='col-md-12 col-lg-12 col-sm-12 d-flex justify-content-center'>
                    <div className='office-about-info'>
                        <h2 className=' t_color f--size-40 f_600 mb-5'>{t("contact_our_office")}</h2>
                    </div>
                </div>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                    <div className='office-about-img'>
                        <Swiper
                              navigation={{
                                nextEl: '.swiper-button-next',
                                prevEl: '.swiper-button-prev',
                              }}  

                              breakpoints={{
                                640: {
                                  slidesPerView: 1,
                                  spaceBetween: 20,
                                },
                                768: {
                                  slidesPerView: 1,
                                  spaceBetween: 20,
                                },
                                1024: {
                                  slidesPerView: 2,
                                  spaceBetween: 35,
                                },
                              }}
                              centeredSlides={true}
                              spaceBetween={20}
                              
                              modules={[Navigation]}
                              className='officeSwiper'
                            >
                            <SwiperSlide>                              
                                <OfficeItem 
                                    officeImg='sede-bogota'
                                    officeCity='Bogotá, Colombia'
                                    officeAddress='Dg. 25g #95a - 85,'
                                    officeNum='Centro Empresarial Buró 25, Oficina 112' />
                            </SwiperSlide>
                            <SwiperSlide>                              
                                <OfficeItem 
                                    officeImg='sede-cali'
                                    officeCity='Cali, Colombia'
                                    officeAddress='Carrera 100 #16-321,'
                                    officeNum='Centro de Negocios Jardín Central, Oficina 1202' />
                            </SwiperSlide>
                            <SwiperSlide>                              
                                <OfficeItem 
                                    officeImg='sede-san-francisco'
                                    officeCity='San Francisco, United States'
                                    officeAddress='50 California Street, '
                                    officeNum='15th Floor' />
                            </SwiperSlide>
                            <BottonsNavSwiper />
                        </Swiper>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

