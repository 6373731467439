import React from 'react';
import './../components/Benefits/benefits.css';
import { useTranslation } from 'react-i18next';
import ParallaxTitle from '../components/Titles/ParallaxTitle';
import BenefitsList from '../components/Benefits/BenefitsList';

function Benefits() {

    const {t} = useTranslation();

  return (
    <>
        <section className="banner--benefics h-75" id="benefits">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-4">
                        <div className="pt-4 pb-4 img-icons text-left">
                            <img className="w-10" src={require("../img/iconos/fondo-home.png")} alt="Fondo Home"/>
                            <img className="w-10" src={require("../img/iconos/fondo-home2.png")} alt="Fondo Home"/>
                            <img className="w-10" src={require("../img/iconos/fondo-home.png")} alt="Fondo Home"/>
                            <img className="w-10" src={require("../img/iconos/fondo-home2.png")} alt="Fondo Home"/>
                        </div>
                    </div>
                    <div className="col-lg-8">                   
                        <div className="w_color text-left pt-3">
                            <h2 className="f--size-50 f_600 l_height09">{t('benefits.banner_title')}</h2>
                            <h3 className="f--size-25 f_600 mb_30">{t('benefits.banner_subTitle')}</h3>  
                            <p className="f--size-18 f_500">{t('benefits.banner_text')}</p>        
                        </div>
                    </div>
                </div>    
            </div>
        </section>
        <section className='benefics--tab -bg-section'>
            <ParallaxTitle 
                Title ={t('benefits.benefits_tab_title')}
                classDiv ="text-center p-5"
                h2Class ="t_color f_600 f--size-40"
            />
            <div className="container">
                <div className="row">
                    <BenefitsList />
                </div>
            </div>
        </section>
    </>
  )
}

export default Benefits;