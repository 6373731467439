import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {  Pagination } from "swiper";
import './officeItem.css';

export default function OfficeItem({ officeImg , officeCity, officeAddress, officeNum}) {
  return (
    <div className='office--item'>
        <div className='office-content' >
            <div className='office-img'>
            <Swiper 
                grabCursor={true}        
                pagination={{
                    dynamicBullets: true,
                    }}
                    modules={[Pagination]}
                className="itemSwiper">
                <SwiperSlide><img src={require(`img/contacts/${officeImg}-3.jpg`)} alt=""/></SwiperSlide>
                <SwiperSlide><img src={require(`img/contacts/${officeImg}-2.jpg`)} alt=""/></SwiperSlide>
                

            </Swiper>
            </div>
            <div className='pt-4 px-4'>
                <h3 className='m_color'>{officeCity}</h3>
                <p className='p_color mb-0 f--size-20'><strong>{officeAddress}</strong></p>
                <p className='p_color f--size-18'>{officeNum}</p>
            </div>
        </div>
    </div>
  )
}

