
import React, { createContext } from "react";
import { BrowserRouter, Route ,Switch, Redirect } from "react-router-dom";
import './App.css';
import './assets/fonts/Futura/fonts.css';
import useLocalStorage from "./assets/useLocalStorage";

/* ---- Pages ----*/
import Home from "./Pages/Home";
import Careers from './Pages/Careers'
import Contact from "./Pages/Contact";
import About from "./Pages/About";
import Devops from "./Pages/Devops";
import Scrum from "./Pages/Scrum";
import Services from "./Pages/Services";
import GreatPlace from './Pages/GreatPlace';
import Dreamcoders from './Pages/Dreamcoders';
import Blog from "./Pages/Blog";
import StaffAugmentation from "./Pages/StaffAugmentation";
import NotFound from "./Pages/404";
import Test from 'components/Home/TestServ/TestServCopy';

/* ---- Menu - Footer - Newsletter ---*/
import Menu from './components/Header/Menu';
import Footer from './components/Footer/Footer';
//import Newsletter from './components/Forms/FormNewsletter/FormNewsletter';
import ScrollToTop from './assets/ScrollToTop';
//import Flotantes from './components/Flotantes/Flotantes';


export const ThemeContext = createContext(null);

function App () {

    const lang = '/:locale(en|es)?';
    //const preference = window.matchMedia("(prefers-color-scheme: light)").matches;
    const [theme, setTheme] = useLocalStorage("theme", false);

    const toggleTheme = () => {
      setTheme(!theme)
    };
 
    return (  

            <BrowserRouter >
              <ThemeContext.Provider value={{ theme, toggleTheme }} >
                <div data-theme={theme ? "dark" : "light"}>
                  {/* <Flotantes /> */}
                  <Route path={lang} component={Menu} />
                  <ScrollToTop />
                  <Switch>
                    <Route exact path={lang} component={Home} />
                    <Route path={`${lang}/careers`} component={Careers} />
                    <Route exact path={`${lang}/contact`} component={Contact} />
                    <Route exact path={`${lang}/staff-augmentation`} component={StaffAugmentation} />
                    <Route exact path={`${lang}/about`} component={About} />
                    <Route exact path={`${lang}/test`} component={Test} />
                    <Route exact path={`${lang}/devops`} component={Devops} />
                    <Route exact path={`${lang}/scrum`} component={Scrum} /> 
                    <Route exact path={`${lang}/services`} component={Services} />
                    <Route exact path={`${lang}/great-place-to-work`} component={GreatPlace} />
                    <Route exact path={`${lang}/dreamcoders`} component={Dreamcoders} />
                    <Route path={`${lang}/blog`} component={Blog} />
                    <Route exact path={`${lang}/404`} component={NotFound} /> 
                    <Redirect to={`${lang}/404`} />

                  </Switch>
                  <Route path={lang} component={Footer} />
                </div>
                {/* <Newsletter typeNewsletter="modal fade"/>     */}
              </ThemeContext.Provider>               
            </BrowserRouter>
            )
        };


export default App;