import React, { useContext } from "react";
import { ThemeContext } from "../../../App";

function TestDarkMode() {

  const { theme } = useContext(ThemeContext);

  return (
    <div className={`${theme==="light" ? "bg-light" : "bg-dark"} vh-100 d-flex align-items-center justify-content-center `}>            
      <h2 className={`text-center  ${theme==="light" ? "text-dark" : "text-light"}`}>Hello Modo {theme}</h2>
      
      {/* <button className="btn " onClick={toggleDarkMode}>Cambiar</button> */}
  </div>
  )
}

export default TestDarkMode;