import React, {  useContext } from 'react';
import { ThemeContext } from "../../App";
import { NavLink } from 'react-router-i18n';
import i18n from 'assets/locales/I18n';
import MenuLinks from './MenuLinks';
import './menu.css';
import { BiSun, BiMoon } from "react-icons/bi";

function Menu ({ location: { pathname }, match: { params: { locale } }, history}) {

    const { theme, toggleTheme } = useContext(ThemeContext);

    const stripLocale = (pathname, locale) => {
        if (!locale) { return pathname; }

        i18n.changeLanguage(locale);
        return pathname.replace(`/${locale}`, '');      
    }

    return (
        <header className="navbar sticky-top navbar-expand-lg -bg-section header_area"  id="header" history={history}>
        <div className='container'>
            <NavLink className="navbar-brand f--size-0 sticky_logo" to={'/'}>
                <img className="logoBlue" src={require("./../../img/logo-blue.png")} alt="Desarrolladores de software DreamCode Software" title="Logo DreamCode Software" width="164"/>
            </NavLink>
            <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" 
                    data-target="#MenuContent" aria-controls="MenuContent" 
                    aria-expanded="false" aria-label="Toggle navigation">
                <span className="menu_toggle">
                    <span className="hamburger">
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                    <span className="hamburger-cross">
                        <span></span>
                        <span></span>
                    </span>
                </span>
            </button>      
            <MenuLinks
                toBlog={(location) => ({ ...location, pathname: '/blog' })}
                toCareers={(location) => ({ ...location, pathname: '/careers' })}
                toSpanish={`/es${stripLocale(pathname, locale)}`}
                toEnglish={`/en${stripLocale(pathname, locale)}`} >
                <button onClick={toggleTheme} className='btn-dark-mode'>
                    {theme ?  <BiSun /> : <BiMoon /> }
                </button> 
            </MenuLinks>
        </div>
    </header>
    );
}

export default Menu;
