import React from 'react';
import './planets.css';
import { useTranslation } from 'react-i18next';

import {  NavLink } from 'react-router-i18n';

function Plants() {

    const { t } = useTranslation('translation', { keyPrefix: 'homePage.service_home' });

  return (
    <>
        <section className="service-body line-break">
            <h2 className='title-services'>{t('service_title')}</h2>
            <h3 className='subtitle-services mt-3'>{t('service_subt')}</h3>
            <NavLink className="btn-services" to={`/staff-augmentation`}>
                {t('read_more')}...
            </NavLink>   
            
            <input checked='checked' className='planet3' id='neptune' name='planet' type='radio'></input>
            <label className='neptune menu' for='neptune'>               
                <div className='info'>
                    <h3>{t('baseTitle')}</h3>
                </div>
            </label>
            <input checked='checked' className='planet2' id='venus' name='planet' type='radio'></input>
            <label className='menu venus' for='venus'>
                <div className='info'>
                <h3>{t('consultoriaTitle')} </h3>
                </div>
            </label>
            <input checked='checked' className='planet1' id='mercury' name='planet' type='radio'></input>
            <label className='menu mercury' for='mercury'>
                <div className='info'>
                <h3>{t('appTitle')}</h3>
                </div>
            </label>
            
                <div className='solar'>
                    
                    <div className='solar_systm '>
                        <div className='planet mercury'>
                            <div className='planet_description mercury'>

                                <h3>{t('appTitle')}</h3>
                                <p>{t('appDescrip')}</p>
                                {/* <label >
                                    <NavLink className='' activeclass="active" spy="true" offset={0} duration={500} to={`/services`}>
                                        {t('read_more')}
                                    </NavLink>   
                                </label>    */}
                            </div>
                            
                            <div className='overlay'></div>
                            
                        </div>
                        
                    </div>
                     <div className='solar_systm '>
                        <div className='planet venus'>
                            <div className='planet_description venus'>
                            <h3>{t('consultoriaTitle')}</h3>
                                <p>{t('consultoriaDescrip')}</p>  
                                {/* <label >
                                    <a href={`/services`}>
                                        {t('read_more')}
                                    </a>   
                                </label>    */}
                            </div>
                            <div className='overlay'></div>
                        </div>
                    </div>
                    <div className='solar_systm '>
                        <div className='planet neptune'>
                            <div className='planet_description neptune'>
                                <h3>{t('baseTitle')}</h3>
                                <p>{t('baseDescrip')}</p>   
                                {/* <label >
                                    <NavLink className='' activeclass="active" spy="true" offset={0} duration={500} to={`/services`}>
                                        {t('read_more')}
                                    </NavLink>   
                                </label>                              */}
                                </div>
                            <div className='overlay'></div>
                        </div>
                    </div>
                </div>
                
        </section>
        
    </>
  )
}

export default Plants;