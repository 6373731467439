import React from 'react';
import './../components/GreatPlace/great-place.css';
import { useTranslation, Trans } from 'react-i18next';
import Fade from 'react-reveal/Fade';
import PlaceToWork from '../components/GreatPlace/PlacetoWork/PlaceToWork';
import MetaDecorator from '../components/MetaDatos/MetaDecorator';
import ReactGA from 'react-ga';
import { ID_GOOGLE_ANALYTICS } from '../assets/Constants'

    // Configuración de Google Analytics
    ReactGA.initialize ({ID_GOOGLE_ANALYTICS}); 
    ReactGA.pageview ("/great-place-to-work");

function GreatPlace() {

  const { t } = useTranslation();

  return (
    <>
      <MetaDecorator 
        title={t('meta_datos.meta_title_great')}
        description={t('meta_datos.meta_description_great')}
        type='Website'
        url="great-place-to-work" />
      <section className='great--place '>
        <div className="container">
          <div className="great--content ">
            <Fade>
              <h1 className='position-relative line-break p_color' >
                <Trans i18nKey="great-place.title_banner">
                    The 99% of our <span>dreamcoders indicate</span>
                  that we are <strong className='t_color'>an excellent <span>place to</span> <span>work</span></strong>
                </Trans>
              </h1>
            </Fade>
            <div className='grid--img'>
              <div className='grid--column grid--img-column'>
                <Fade bottom>
                  <div className='great--img mod--1'>
                  </div>
                </Fade>
                <Fade top>
                  <div className='great--img mod--2'>
                    <div className="img--hover"></div>
                  </div>
                </Fade>
              </div>
            </div>
            <div className='great--place-area'>
            <Fade left>
              <figure className='great--place-logo'>
                <img src={require("../img/great-place/certification-great-place-to-work.png")} alt="Fondo Home" width="130" />
              </figure>
            </Fade>
            </div>
          </div>
        </div>
      </section>

      <section className='great--place h-75'>
        <div className="container">
          <div className='row border-top border-dark'>
            <div className='col-lg-1 col-md-1 col-sm-12 '>
            
              <div className='content--intro'>
                
              </div>
          
            </div>
            <div className='col-lg-11 col-md-11 col-sm-12'>
            <Fade right>
                <div className='content--intro'>
                  <p className='p_color f--size-18 line-break'>{t('great-place.2023')}</p>
                </div>
              </Fade>
            </div>
            <div className='line mod--lines'>
              
            </div>
          </div>
        </div>
      </section>
      <PlaceToWork />
    </>
  )
}

export default GreatPlace;