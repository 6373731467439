import React from 'react';
import './team.css'
import { useTranslation } from 'react-i18next';

function Team() {

    const { t } = useTranslation('translation', { keyPrefix: 'aboutPage.team_about' });

  return (
    <section className="position-relative about-team mt_60 overflow-hidden" id="team">
        <div className="container"> 
            <div className="row ">
                <div className="col-lg-12 mt_20 mb_60">
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-xl-12 mb_30">
                            <div className='position-absolute top-0 '>
                                <h2 className="f_600 f--size-60 text-center x_color">{t("team_title")}</h2>
                            </div>
                        </div>
                        <div className="col-sm-12 col-xl-12 mt-5">
                            <div className="row team-content">
                                <div className="col-6 col-md-3">
                                    <img className='img-fluid' src={require("img/about/team/horacio-team.png")} title="Image the team member Horacio borrero" alt="Team member Horacio borrero"/>
                                    <div className="text-left my-3">
                                        <h3 className="f_p m-0 f--size-20 f_600 t_color">Horacio Borrero</h3>
                                        <h5 className='f--size-15 p_color'>{t("team_horacio_name")}</h5>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <img className='img-fluid' src={require("img/about/team/francisco-team.png")} title="Image the team member Francisco Chavez" alt="Team member Francisco Chavez"/>
                                    <div className="text-left my-3">
                                        <h3 className="f_p m-0 f--size-20 f_600 t_color">Francisco Chaves</h3>
                                        <h5 className='f--size-15 p_color'>{t("team_francisco_name")}</h5>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <img className='img-fluid' src={require("img/about/team/jonathan-team.png")} title="Image the team member Jonathan Ortiz" alt="Team member Jonathan Ortiz"/>
                                    <div className="text-left my-3">
                                        <h3 className="f_p m-0 f--size-20 f_600 t_color">Jonathan Ortiz</h3>
                                        <h5 className='f--size-15 p_color'>{t("team_jonathan_name")}</h5>
                                    </div>
                                </div>
                                <div className="col-6 col-md-3">
                                    <img className='img-fluid' src={require("img/about/team/leonardo-team.png")} title="Image the team member Leonardo Vargas" alt="Team member Leonardo Vargas"/>
                                    <div className="text-left my-3">
                                        <h3 className="f_p m-0 f--size-20 f_600 t_color">Leonardo Vargas</h3>
                                        <h5 className='f--size-15 p_color'>{t("team_leonardo_name")}</h5>
                                    </div>
                                </div>
                            </div>
                        </div>                                                          
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Team;