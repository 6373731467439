import React from 'react';
import { Link } from 'react-router-i18n';
import Markdown from 'markdown-to-jsx';

function PostItem ({ imglink, title, date, url, read, category, content}) {

    const shortContent = content.split(" ").slice(0, 20).join(" ") + "...";

  return (
      <article className='col-lg-4 col-sm-12 col-md-3' >
          <div className="img-post">
              <img className="img-fluid" src={require(`img/posts/${imglink}`)} alt={title} title={title}/>
          </div>
          <div className="post--content t_color position-relative py-4">
              <h2 className="f_p f_700 t_color ">
                  <Link className="t_color" to={`/blog/${url}`}>{title}</Link>
              </h2>
              <div className="entry--post-info mb-2 text-capitalize">
                  <code>{date}</code>
                  <code>{read}</code>
                  <code>{category}</code>                                                        
              </div>
              <Markdown children={shortContent}  />
          </div>
      </article>
  )
}
export default PostItem;
