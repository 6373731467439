import React from 'react';
import './techTalent.css'
import { useTranslation } from 'react-i18next';


function TechTalent () {

  const { t } = useTranslation('translation', 
  { keyPrefix: 'staff' });
  const staffItems = t('our_tech_talent.items_talents', { returnObjects: true })
console.log(staffItems[1].title);
  return (
    
        <div className="our_tech_talent ">
          <div className='line-break text-center'>
            <h2 className='f--size-50 text-uppercase f_p f_600 w_color'>{t('our_tech_talent.title')}</h2>
          </div>
          <div className='container-grad'>
              <div class="column col-one">
                <div class="fila-one">
                  <div class="col-item color-1">
                    <p>{staffItems[2].title}</p>
                    <img className='ml-auto' src={require ('img/staff-augmentation/talent-agile.svg').default} width={50} height={50} alt={staffItems[2].title}></img>
                  </div>
                  <div class="col-item color-2">
                    <p>{staffItems[6].title}</p>
                    <img className='ml-auto' src={require ('img/staff-augmentation/talent-adaptation.svg').default} width={50} height={50} alt={staffItems[6].title}></img>
                  </div>
                </div>
                <div class="fila-two">
                  <div class="col-item color-3">
                    <p>{staffItems[5].title}</p>
                    <img className='ml-auto' src={require ('img/staff-augmentation/talent-transformation.svg').default} width={50} height={50} alt={staffItems[5].title}></img>
                  </div>
                </div>
              </div>

              <div class="column col-two">
                <div class="fila-one">
                  <div class="col-item color-4 line-break">
                    <p>{staffItems[4].title}</p>
                    <img className='ml-auto' src={require ('img/staff-augmentation/talent-valor.svg').default} width={50} alt={staffItems[4].title}></img>
                  </div>
                </div>
                <div class="fila-two">
                  <div class="col-item color-5">
                    <p>{staffItems[3].title}</p>
                    <img className='ml-auto' src={require ('img/staff-augmentation/talent-performance.svg').default} width={50} alt={staffItems[3].title}></img>
                  </div>
                </div>
              </div>

              <div class="column col-three">
                <div class="fila-one">
                  <div class="col-item color-6">
                    <p>{staffItems[0].title}</p>
                    <img className='ml-auto' src={require ('img/staff-augmentation/talent-innovation.svg').default} width={50} alt={staffItems[0].title}></img>
                  </div>
                </div>
                <div class="fila-two">
                  <div class="col-item color-7">
                    <p>{staffItems[1].title}</p>
                    <img className='ml-auto' src={require ('img/staff-augmentation/talent-culture.svg').default} width={50} alt={staffItems[1].title}></img>
                  </div>
                </div>
              </div>
            
          </div>              
        </div>
    
  )
}

export default TechTalent;