import React from 'react';
import './video-careers.css';
import { useTranslation } from 'react-i18next';
//import { GiSoundOff } from "react-icons/gi";


function VideoComponent() {

    const { t } = useTranslation();

  return (
    <section className='jobsVideo-section '>
        <div className=" jobsVideo-background">
            <iframe className="jobsVideo-embed" 
                    frameBorder="0" 
                    allowFullScreen
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  
                    title="Sueña para transformar el futuro." 
                    width="640" 
                    height="360" 
                    src={t('careers.url_video')} >
            </iframe>            
        </div>
        {/* <button className="position-absolute float-left bg-white z-index-9" type="button" ><GiSoundOff />
        </button> */}
    </section>
  );
}

export default VideoComponent;
